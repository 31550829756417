import {
  UserProfileFields,
  beerify,
  debeerify,
  setUserProfileServerError,
  useAlerts,
  useEnvironment,
} from "@keycloak/keycloak-ui-shared";
import {
  ActionGroup,
  Alert,
  Button,
  ExpandableSection,
  Form,
  Spinner,
} from "@patternfly/react-core";
import { ExternalLinkSquareAltIcon } from "@patternfly/react-icons";
import { TFunction } from "i18next";
import { useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  getPersonalInfo,
  getSupportedLocales,
  savePersonalInfo,
} from "../account-ui/api/methods";
import {
  UserProfileMetadata,
  UserRepresentation,
} from "../account-ui/api/representations";
import { Page } from "../account-ui/components/page/Page";
import type { Environment } from "../account-ui/environment";
import { TFuncKey, i18n } from "../account-ui/i18n";
import { usePromise } from "../account-ui/utils/usePromise";
import { Permissions, getPermissions } from "../api/api";

export const PersonalInfo = () => {
  const { t } = useTranslation();
  const context = useEnvironment<Environment>();

  const [personalInfo, setPersonalInfo] = useState<UserRepresentation>();
  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadata>();
  const [supportedLocales, setSupportedLocales] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Permissions>();

  const form = useForm<UserRepresentation>({ mode: "onChange" });
  const { handleSubmit, reset, setValue, setError } = form;
  const { addAlert, addError } = useAlerts();

  usePromise(
    (signal) =>
      Promise.all([
        getPersonalInfo({ signal, context }),
        getSupportedLocales({ signal, context }),
        getPermissions({ signal, context }),
      ]),
    ([personalInfo, supportedLocales, permissions]) => {
      const upm = personalInfo.userProfileMetadata as UserProfileMetadata;

      upm.attributes.find((a) => a.name == "email")!.readOnly = true;

      setUserProfileMetadata(personalInfo.userProfileMetadata);
      setSupportedLocales(supportedLocales);
      reset(personalInfo);
      Object.entries(personalInfo.attributes || {}).forEach(([k, v]) =>
        setValue(`attributes[${beerify(k)}]`, v)
      );
      setPermissions(permissions);
      setPersonalInfo(personalInfo);

      if (permissions?.schulportal) {
        setValue(
          `username`,
          permissions.schulportalName || "schulportal-login"
        );
        upm.attributes.forEach((a) => (a.readOnly = true));
      }
    }
  );

  const onSubmit = async (user: UserRepresentation) => {
    try {
      const attributes = Object.fromEntries(
        Object.entries(user.attributes || {}).map(([k, v]) => [debeerify(k), v])
      );
      delete user.username;
      await savePersonalInfo(context, { ...user, attributes });
      const locale = attributes["locale"]?.toString();
      if (locale) {
        i18n.changeLanguage(locale, (error) => {
          if (error) {
            console.warn("Error(s) loading locale", locale, error);
          }
        });
      }
      context.keycloak.updateToken();
      addAlert(t("accountUpdatedMessage"));
    } catch (error) {
      console.log(error);

      addError(t("accountUpdatedError").toString());

      setUserProfileServerError(
        { responseData: { errors: error as any } },
        (name: string | number, error: unknown) =>
          setError(name as string, error as ErrorOption),
        ((key: TFuncKey, param?: object) => t(key, param as any)) as TFunction
      );
    }
  };

  if (!userProfileMetadata) {
    return <Spinner />;
  }

  const {
    // updateEmailFeatureEnabled,
    // updateEmailActionEnabled,
    isRegistrationEmailAsUsername,
    isEditUserNameAllowed,
  } = context.environment.features;
  return (
    <Page title={t("personalInfo")} description={t("personalInfoDescription")}>
      <Form
        isHorizontal
        onSubmit={handleSubmit(onSubmit)}
        className="personal-info-form"
      >
        <UserProfileFields
          form={form}
          userProfileMetadata={userProfileMetadata}
          supportedLocales={[]}
          currentLocale={context.environment.locale}
          t={
            ((key: unknown, params) =>
              t(key as TFuncKey, params as any)) as TFunction
          }
          renderer={(attribute) =>
            attribute.name === "email" &&
            // updateEmailFeatureEnabled &&
            // updateEmailActionEnabled &&
            (!isRegistrationEmailAsUsername || isEditUserNameAllowed) ? (
              <Button
                id="update-email-btn"
                style={{ paddingLeft: 0 }}
                variant="link"
                onClick={() =>
                  context.keycloak.login({
                    action: "UPDATE_EMAIL_WITH_VERIFICATION",
                  })
                }
                icon={<ExternalLinkSquareAltIcon />}
                iconPosition="right"
              >
                {t("updateEmail")}
              </Button>
            ) : undefined
          }
        />
        {!permissions?.schulportal && (
          <ActionGroup>
            <Button
              data-testid="save"
              type="submit"
              id="save-btn"
              variant="primary"
            >
              {t("save")}
            </Button>
            <Button
              data-testid="cancel"
              id="cancel-btn"
              variant="link"
              onClick={() => reset()}
            >
              {t("cancel")}
            </Button>
          </ActionGroup>
        )}
        {context.environment.features.deleteAccountAllowed && (
          <ExpandableSection
            data-testid="delete-account"
            toggleText={t("deleteAccount")}
          >
            <Alert
              isInline
              title={t("deleteAccount")}
              variant="danger"
              actionLinks={
                <Button
                  id="delete-account-btn"
                  variant="danger"
                  onClick={() =>
                    context.keycloak.login({
                      action: "delete_account",
                    })
                  }
                  className="delete-button"
                >
                  {t("delete")}
                </Button>
              }
            >
              {t("deleteAccountWarning")}
            </Alert>
          </ExpandableSection>
        )}
      </Form>
    </Page>
  );
};

export default PersonalInfo;
