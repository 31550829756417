import { ErrorPage } from "@keycloak/keycloak-ui-shared";
import type { IndexRouteObject, RouteObject } from "react-router-dom";
import { Bsp } from "./app/Bsp";
import { Otp } from "./app/Otp";
import { Password } from "./app/Password";
import PersonalInfo from "./app/PersonalInfo";
import Verbundswechsel from "./app/Verbundswechsel";
import { environment } from "./environment";
import { Root } from "./root/Root";

export const PersonalInfoRoute: IndexRouteObject = {
  index: true,
  element: <PersonalInfo />,
};

export const ApplicationsRoute: RouteObject = {
  path: "applications",
  element: <Verbundswechsel />,
};

export const PasswordRoute: RouteObject = {
  path: "password",
  element: <Password />,
};

export const TotpRoute: RouteObject = {
  path: "totp",
  element: <Otp />,
};

export const IdentityRoute: RouteObject = {
  path: "identity",
  element: <Bsp />,
};

export const RootRoute: RouteObject = {
  path: decodeURIComponent(new URL(environment.baseUrl).pathname),
  element: <Root />,
  errorElement: <ErrorPage />,
  children: [
    ApplicationsRoute,
    PersonalInfoRoute,
    PasswordRoute,
    TotpRoute,
    IdentityRoute,
  ],
};

export const routes: RouteObject[] = [RootRoute];
