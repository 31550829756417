import { useEnvironment } from "@keycloak/keycloak-ui-shared";
import {
  Button,
  DataList,
  DataListAction,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  PageSection,
  Spinner,
  Split,
  SplitItem,
  Title,
} from "@patternfly/react-core";
import { CSSProperties, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getCredentials } from "../account-ui/api/methods";
import {
  CredentialContainer,
  CredentialMetadataRepresentation,
} from "../account-ui/api/representations";
import { Page } from "../account-ui/components/page/Page";
import { formatDate } from "../account-ui/utils/formatDate";
import { usePromise } from "../account-ui/utils/usePromise";
import { TFuncKey } from "../i18n";

export const Otp = () => {
  const { t } = useTranslation();
  const context = useEnvironment();
  const { login } = context.keycloak;

  const [credentials, setCredentials] = useState<CredentialContainer[]>();

  usePromise(
    (signal) => getCredentials({ signal, context }),
    setCredentials,
    []
  );

  const credentialRowCells = (
    credMetadata: CredentialMetadataRepresentation
  ) => {
    const credential = credMetadata.credential;
    const maxWidth = {
      "--pf-v5-u-max-width--MaxWidth": "300px",
    } as CSSProperties;
    const items = [
      <DataListCell
        key="title"
        data-testrole="label"
        className="pf-v5-u-max-width"
        style={maxWidth}
      >
        {credential.userLabel || t(credential.type as TFuncKey)}
      </DataListCell>,
    ];

    if (credential.createdDate) {
      items.push(
        <DataListCell
          key={"created" + credential.id}
          data-testrole="created-at"
        >
          <Trans i18nKey="credentialCreatedAt">
            <strong></strong>
            {{ date: `: ${formatDate(new Date(credential.createdDate))}` }}
          </Trans>
        </DataListCell>
      );
    }
    return items;
  };

  if (!credentials) {
    return <Spinner />;
  }

  const credentialUniqueCategories = [
    ...new Set(credentials.map((c) => c.category)),
  ];

  return (
    <div id="lernraum-totp">
      <Page title={t("signingIn")} description={t("signingInDescription")}>
        {credentialUniqueCategories
          .filter((cat) => cat == "two-factor")
          .map((category) => (
            <PageSection
              key={category}
              variant="light"
              className="pf-v5-u-px-0"
            >
              <Title headingLevel="h2" size="xl" id={`${category}-categ-title`}>
                {t(category as TFuncKey)}
              </Title>
              {credentials
                .filter((cred) => cred.category == category)
                .map((container) => (
                  <>
                    <Split className="pf-v5-u-mt-lg pf-v5-u-mb-lg">
                      <SplitItem>
                        <Title
                          headingLevel="h3"
                          size="md"
                          className="pf-v5-u-mb-md"
                          data-testid={`${container.type}/help`}
                        >
                          <span
                            className="cred-title pf-v5-u-display-block"
                            data-testid={`${container.type}/title`}
                          >
                            {t(container.displayName as TFuncKey)}
                          </span>
                        </Title>
                        {/* <span data-testid={`${container.type}/help-text`}>
                        {t(container.helptext as TFuncKey)}
                      </span> */}
                      </SplitItem>
                    </Split>

                    <DataList
                      aria-label="credential list"
                      className="pf-v5-u-mb-xl"
                      data-testid={`${container.type}/credential-list`}
                    >
                      {container.userCredentialMetadatas.length === 0 && (
                        <div>
                          {container.createAction && (
                            <SplitItem isFilled>
                              <div
                                className="pf-v5-u-float-right"
                                id="lernraum-top-create"
                              >
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    login({
                                      action: container.createAction,
                                    })
                                  }
                                  className="pf-v5-u-display-none pf-v5-u-display-inline-flex-on-lg"
                                  data-testid={`${container.type}/create`}
                                >
                                  {t("setUpNew", {
                                    name: t(
                                      `${container.type}-display-name` as TFuncKey
                                    ),
                                  })}
                                </Button>
                              </div>
                            </SplitItem>
                          )}
                        </div>
                      )}

                      {container.userCredentialMetadatas.map((meta) => (
                        <DataListItem key={meta.credential.id}>
                          <DataListItemRow id={`cred-${meta.credential.id}`}>
                            <DataListItemCells
                              className="pf-v5-u-py-0"
                              dataListCells={[
                                ...credentialRowCells(meta),
                                <DataListAction
                                  key="action"
                                  id={`action-${meta.credential.id}`}
                                  aria-label={t("updateCredAriaLabel")}
                                  aria-labelledby={`cred-${meta.credential.id}`}
                                >
                                  {container.removeable ? (
                                    <Button
                                      variant="danger"
                                      data-testrole="remove"
                                      onClick={() => {
                                        login({
                                          action:
                                            "delete_credential:" +
                                            meta.credential.id,
                                        });
                                      }}
                                    >
                                      {t("delete")}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="secondary"
                                      onClick={() => {
                                        if (container.updateAction)
                                          login({
                                            action: container.updateAction,
                                          });
                                      }}
                                      data-testrole="update"
                                    >
                                      {t("update")}``
                                    </Button>
                                  )}
                                </DataListAction>,
                              ]}
                            />
                          </DataListItemRow>
                        </DataListItem>
                      ))}
                    </DataList>
                  </>
                ))}
            </PageSection>
          ))}
      </Page>
    </div>
  );
};
