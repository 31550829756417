import { CallOptions } from "../account-ui/api/methods";
import { parseResponse } from "../account-ui/api/parse-response";
import { request } from "../account-ui/api/request";
import { joinPath } from "../utils/joinPath";

export type Permissions = {
  schulportal: boolean;
  schulportalName: string;
  sba: boolean;
};

export async function getPermissions({
  signal,
  context,
}: CallOptions): Promise<Permissions> {
  const response = await request(
    "",
    context,
    {
      signal,
    },
    new URL(
      joinPath(
        context.environment.serverBaseUrl,
        "/realms/",
        context.environment.realm,
        "/lernraum2-theme/permissions"
      )
    )
  );
  return parseResponse<Permissions>(response);
}

export type RoleModel = {};

export type ClientRoleEntry = {
  clientId: string;
  clientName: string;
  roleName: string;
  roleDescription: string;
};

export type ClientModel = {
  name: string;
  clientId: string;
  description: string;
};

export type Application = {
  effectiveUrl?: string;
  realmRolesAvailable: RoleModel[];
  resourceRolesAvailable: { [clientId: string]: ClientRoleEntry };
  client: ClientModel;
  clientScopesGranted: string[];
  additionalGrants: string[];
};

export async function getApplications({
  signal,
  context,
}: CallOptions): Promise<Application[]> {
  const response = await request(
    "",
    context,
    {
      signal,
    },
    new URL(
      joinPath(
        context.environment.serverBaseUrl,
        "/realms/",
        context.environment.realm,
        "/lernraum2-theme/applications"
      )
    )
  );
  return parseResponse<Application[]>(response);
}

export async function getClientGroups({
  signal,
  context,
}: CallOptions): Promise<string[]> {
  const response = await request(
    "",
    context,
    {
      signal,
    },
    new URL(
      joinPath(
        context.environment.serverBaseUrl,
        "/realms/",
        context.environment.realm,
        "/lernraum2-theme/clientGroups"
      )
    )
  );
  return parseResponse<string[]>(response);
}
