import { useEnvironment } from "@keycloak/keycloak-ui-shared";
import { Button, Spinner, Title } from "@patternfly/react-core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPersonalInfo } from "../account-ui/api/methods";
import { Page } from "../account-ui/components/page/Page";
import { usePromise } from "../account-ui/utils/usePromise";
import { getPermissions, Permissions } from "../api/api";

export const Password = () => {
  const { t } = useTranslation();
  const context = useEnvironment();
  const { login } = context.keycloak;

  const [personalInfo, setPersonalInfo] = useState<{
    attributes: { [key: string]: string[] };
  }>();
  const [permissions, setPermissions] = useState<Permissions>();

  usePromise(
    (signal) =>
      Promise.all([
        getPermissions({ signal, context }),
        getPersonalInfo({ signal, context }),
      ]),
    ([permissions, personalInfo]) => {
      setPermissions(permissions);
      setPersonalInfo(personalInfo);
    }
  );

  if (!personalInfo) {
    return <Spinner />;
  }

  return (
    <Page title="Kennwort" description="Kennwort prüfen und neu festlegen">
      {permissions?.schulportal ? (
        <div className="lernraum-password-change">
          <Title headingLevel="h2">Anmeldung mit dem Berliner Schulportal</Title>
          <div>
            Das Benutzerkonto ist bereits mit dem Berliner Schulportal
            verbunden. Das Kennwort kann deshalb nur im{" "}
            <a href="https://schulportal.berlin.de/">Berliner Schulportal</a>{" "}
            geändert werden.
          </div>
        </div>
      ) : (
        <>
          <Button
            variant="secondary"
            onClick={() => {
              login({ action: "UPDATE_PASSWORD" });
            }}
            data-testrole="update"
          >
            {t("update")}
          </Button>

          {Object.entries(personalInfo.attributes).filter(([k, v]) =>
            k.startsWith("last_password_change_")
          ).length > 0 ? (
            <div className="lernraum-password-change">
              <Title headingLevel="h2">Kennwort-Änderungen</Title>
              <ul>
                {Object.entries(personalInfo.attributes)
                  .filter(([k, v]) => k.startsWith("last_password_change_"))
                  .map(([k, v]) => (
                    <li>
                      Am{" "}
                      <strong>
                        {new Date(parseInt(k.substring(21))).toLocaleString()}{" "}
                        Uhr
                      </strong>
                      , hat <span>{v[0].split(":")[1]}</span> das Kennwort
                      zurückgesetzt.
                    </li>
                  ))}
              </ul>
            </div>
          ) : null}
        </>
      )}
    </Page>
  );
};
