import { useEnvironment } from "@keycloak/keycloak-ui-shared";
import { Spinner } from "@patternfly/react-core";
import { useState } from "react";
import { usePromise, Page } from "../account-ui";
import { Application, getApplications, getClientGroups } from "../api/api";
import { Environment } from "../environment";
import { ExternalLinkSquareAltIcon } from "@patternfly/react-icons";

function ApplicationCard({ application }: { application: Application }) {
  return (
    <div
      className={`lernraum-card bg-lernraum-verbund bg-${application.client.clientId}`}
    >
      <div className="lernraum-card-title">
        {application.effectiveUrl ? (
          <a href={application.effectiveUrl}>
            <ExternalLinkSquareAltIcon />
            <h4>
              {application.client.name
                ? application.client.name
                : application.client.clientId}
            </h4>
          </a>
        ) : (
          <h4>
            {application.client.name
              ? application.client.name
              : application.client.clientId}
          </h4>
        )}
      </div>

      <div className="lernraum-card-description">
        {application.client.description}
      </div>
    </div>
  );
}

function Verbundswechsel() {
  const context = useEnvironment<Environment>();
  const [applications, setApplications] = useState<Application[]>();
  const [clientGroups, setClientGroups] = useState<string[]>();

  usePromise(
    (signal) =>
      Promise.all([
        getApplications({ signal, context }),
        getClientGroups({ signal, context }),
      ]),
    ([applications, clientGroups]) => {
      setApplications(applications);
      setClientGroups(clientGroups);
    }
  );

  if (!applications) {
    return <Spinner />;
  }

  const candidates = applications.filter(
    (application) =>
      (application.client.clientId.startsWith("moodle-") ||
        application.client.clientId.startsWith("split-")) &&
      !application.client.clientId.startsWith("moodle-verbund") &&
      !application.client.clientId.startsWith("moodle-portal") &&
      !application.client.clientId.startsWith("moodle-osz")
  );

  return (
    <Page
      title="Wo soll es hingehen?"
      description="Bitte den Bereich wählen, in dem die eigene Schule liegt. Alles,
              was nicht den eigenen Schulbereich betrifft, findet sich unter
              „Sonstige”."
    >
      <div className="lernraum-cards">
        {candidates
          .filter((application) =>
            Object.keys(application.resourceRolesAvailable).includes(
              application.client.clientId
            )
          )
          .map((application) => (
            <ApplicationCard application={application} />
          ))}

        {candidates
          .filter(
            (application) =>
              clientGroups?.includes(application.client.clientId) &&
              !Object.keys(application.resourceRolesAvailable).includes(
                application.client.clientId
              )
          )
          .map((application) => (
            <ApplicationCard application={application} />
          ))}

        <hr />

        {applications
          .filter((application) =>
            application.client.clientId.startsWith("moodle-portal")
          )
          .map((application) => (
            <ApplicationCard application={application} />
          ))}
      </div>
    </Page>
  );
}

export default Verbundswechsel;
