import { Button } from "@patternfly/react-core";
import { useState } from "react";
import {
  linkAccount,
  LinkedAccountRepresentation,
  useEnvironment,
  usePromise,
} from "../account-ui";
import { Page } from "../account-ui/components/page/Page";
import { getPermissions, Permissions } from "../api/api";
import { Environment } from "../environment";

export const Bsp = () => {
  const context = useEnvironment<Environment>();
  const [permissions, setPermissions] = useState<Permissions>();

  const link = async (account: LinkedAccountRepresentation) => {
    try {
      const { accountLinkUri } = await linkAccount(context, account);
      location.href = accountLinkUri;
    } catch (error) {
      alert(error);
    }
  };

  usePromise(
    (signal) => Promise.all([getPermissions({ signal, context })]),
    ([permissions]) => {
      setPermissions(permissions);
    }
  );

  return (
    <Page
      title="Benutzerkonto verbinden"
      description="Mein Benutzerkonto im Lernraum Berlin mit meinem Benutzerkonto im
            Berliner Schulportal verbinden."
    >
      {permissions?.sba ||
      (permissions?.schulportal && permissions.schulportalName) ? (
        <div className="schulportal-name">{permissions.schulportalName}</div>
      ) : (
        <Button
          id={`Schulportal-idp-link`}
          variant="primary"
          onClick={() =>
            link({
              providerName: "Schulportal",
              connected: false,
              displayName: "Schulportal",
              providerAlias: "Schulportal",
              linkedUsername: "",
              social: false,
            })
          }
        >
          Verbinden
        </Button>
      )}
    </Page>
  );
};
