import { KeycloakProvider } from "@keycloak/keycloak-ui-shared";
import { Spinner } from "@patternfly/react-core";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../account-ui";
import { environment } from "../environment";
import { PageNav } from "./PageNav";

export const Root = () => {
  return (
    <KeycloakProvider environment={environment}>
      <div className="lernraum-header-wrapper">
        <div className="lernarum-content-wrapper lernraum-inner-header">
          <Header />
          <img
            className="lernraum-logo2"
            src={environment.resourceUrl + "/images/senbjf-masterplan-logo.png"}
          />
        </div>
      </div>
      <div className="lernraum-main">
        <div className="lernraum-sidebar">
          <PageNav />
        </div>

        <div className="lernraum-content">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <div className="lernraum-footer-wrapper">
        <div className="lernarum-content-wrapper">
          <div className="lernraum-footer">
            <a
              href="https://www.lernraum-berlin.de/start/de/service/impressum/"
              title="Impressum"
              target="_blank"
            >
              Impressum
            </a>
            <a
              href="https://www.lernraum-berlin.de/start/de/service/datenschutz/"
              title="Datenschutz"
              target="_blank"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </KeycloakProvider>
  );
};
